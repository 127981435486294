<template>
  <div id="register-loading" :class="{'dark-mode': $store.state.isDarkMode}">
    <div
        class="register-loading-icon-container"
        v-show="gifIsShow"
    >
      <img :src="$store.state.isDarkMode ? 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/loading-image-dark.gif' : 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/loading-image.gif'" alt="" class="dark-style">
      <div>借款申请正在智能评估中…</div>
    </div>
    <van-popup
        v-model="protocolPopupIsShow"
        position="bottom"
        :close-on-click-overlay="false"
        round
    >
      <div class="protocol-popup-container">
        <div
            class="protocol-content-container"
            ref="protocol-content-container"
        >
          <van-tabs
              v-model="protocolsTitleIndex"
              animated
              :ellipsis="false"
          >
            <van-tab
                v-for="(item, index)
                in bigAgreementName"
                :title="item"
                :key="index"
            >
              <div
                  v-if="item === '个人信息授权书' && protocolPopupIsShow"
                  v-html="protocolContentHtml"
                  :class="{
                    'protocol-content': true,
                    'protocol-content-no-scroll': stopSubmit
                  }"
                  ref="content"
              >
              </div>
              <iframe
                  v-else
                  :src="bigAgreementName.length === 2 ? bigAgreementFile[0] : bigAgreementFile[index]"
                  frameborder="0"
              />
            </van-tab>
          </van-tabs>
        </div>
        <div
            class="protocol-do-agree-button"
            v-throttle="2000"
            @click="doSubmitApiProduct"
        >
          我同意并申请
        </div>
        <div
            class="protocol-refuse-button"
            @click="refuseApiProductProtocol"
        >
          我不同意
        </div>
      </div>
    </van-popup>
    <van-popup
        round
        v-model="refuseProtocolPopupIsShow"
        :close-on-click-overlay="false"
    >
      <div class="refuse-protocol-popup-container">
        <h3>流程太繁琐？</h3>
        <p>我们采用自动化AI匹配</p>
        <p>您所填写的资料我们会高度保密</p>
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/finish-apply-button.png"
            alt=""
            v-throttle="2000"
            @click="doSubmitApiProduct"
        />
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/to-super-loan-button.png"
            alt=""
            @click="apiProductApplyCancel"
        />
      </div>
    </van-popup>
    <div
        class="count-down-cover"
        v-show="countDownCoverIsShow"
    >
      <img
          :src="$store.state.isDarkMode ? 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image-dark.gif' : 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image.gif'"
          alt=""
      >
    </div>
  </div>
</template>

<script>
import { getToken, setToken } from '_utils/token'
import getSearch from '_utils/getSearch'

import { apply_api_product, get_api_product } from '_network/apiProduct'
import { get_agreement_list } from '_network/submitInfo'
import { apply_big_loan_product, save_personal_protocol_image } from '_network/product'

import personalContent from '_utils/personalContent'
import getNowDate from '_utils/time'
import html2canvas from 'html2canvas'

export default {
  data() {
    return {
      // 这里的产品只会是api产品，用于用户申请本地api产品时弹出的协议弹窗内容信息替换
      product: {},
      // 协议名称展示集合
      bigAgreementName: ['个人信息授权书'],
      // 协议展示地址集合
      bigAgreementFile: [],
      // 个人信息授权书内容
      protocolContentHtml: '',
      // 个人信息授权书弹窗显隐状态
      protocolPopupIsShow: false,
      // 多个个人信息授权书弹框列表时默认展示的协议索引值
      protocolsTitleIndex: 0,
      // 该状态在协议弹框弹出时为true，在协议截图完成时改为false，用来阻止用户滑动协议以及点击提交申请的按钮
      stopSubmit: false,
      // 产品匹配成功后3秒的遮罩层显隐
      countDownCoverIsShow: false,
      // 用户点击协议弹框的 我不同意 后弹出的确认弹框
      refuseProtocolPopupIsShow: false,
      // gif动图是否展示
      gifIsShow: !process.env.VUE_APP_NO_REGISTER_LOADING_GIF
    }
  },
  methods: {
    async pageRedirect() {
      // 该渠道直接从链接上拿token然后跳贷超
      if (process.env.VUE_APP_REGISTER_LOADING_TO_SUPER_LOAN) {
        const token = getSearch().token
        // 有token才执行
        if (token) {
          setToken(token)
          await this.$router.replace('/superLoan')
          return
        }
      }

      if (process.env.VUE_APP_REGISTER_PATH === '_views/RegisterLD') {
        await this.$router.replace('/register')
        return
      }


      // 没有token时无论如何直接跳注册页，同时清除所有缓存
      if (!getToken()) {
        localStorage.clear()
        await this.$router.replace('/register')
        return
      }

      // 跳转下载
      if (localStorage.getItem('toDownload') === '1') {
        this.$toDownload(0)
        return
      }

      // 跳转特殊页面
      if (localStorage.getItem('inReview') === '1') {
        await this.$router.replace('/inReview')
        return
      }

      // 跳转特殊页面
      if (localStorage.getItem('isFailed') === '1') {
        await this.$router.replace('/fail')
        return
      }

      // 跳转特殊页面
      if (localStorage.getItem('isSuccess') === '1') {
        await this.$router.replace('/success')
        return
      }

      // 没有submitSuccess缓存字段代表没有提交资料，此时跳转至提交资料的页面
      if (!localStorage.getItem('submitSuccess')) {
        await this.$router.replace('/loanInfo')
        return
      }
      const product = JSON.parse(localStorage.getItem('bigLoanProduct'))
      // if (!product) {
      //   // 这里代表了用户没有点击api中间页的申请，同时产品也不是api产品，所以跳转普通大额贷的成功页
      //   localStorage.clear()
      //   await this.$router.replace('/register')
      //   return
      // }
      if (product && product.instApiState !== 1 && !localStorage.getItem('applySubmit')) {
        // 这里代表了用户没有点击api中间页的申请，同时产品也不是api产品，所以跳转普通大额贷的成功页
        await this.$router.replace('/examining')
        return
      }

      // 对于不跳贷超停留在产品成功页的渠道，如果匹配到了api产品就就去api展示页，否则去大额贷展示页
      if (process.env.VUE_APP_PRODUCT_DISPLAY_PAGE_NOT_DO_REDIRECT) {
        const apiProductApplySuccess = localStorage.getItem('apiProductApplySuccess')
        const product = JSON.parse(localStorage.getItem('bigLoanProduct'))

        // 该渠道如果没有匹配到api或者大额贷产品，则会直接进入fail路由，那么上面就已经会被拦截，因此不用考虑无产品的问题
        if (apiProductApplySuccess === '1') {
          // 该状态代表匹配到了本地api并且点击了申请，且已经成功，所以跳转api成功页
          await this.$router.replace('/apiProductMiddlePage')
        } else if (product.instApiState !== 1) {
          // 这里代表了用户没有点击api中间页的申请，同时产品也不是api产品，所以跳转普通大额贷的成功页
          await this.$router.replace('/examining')
        } else {
          // 用户匹配到了api产品进入了中间页但是没有点击申请，这种情况让他重新走流程
          localStorage.clear()
          await this.$router.replace('/register')
        }
        return
      }

      // 该字段为1时不跳贷超
      // const quickLoanToSuperLoan = localStorage.getItem('quickLoanToSuperLoan')
      // if (quickLoanToSuperLoan === '1') {
      //   await this.$router.replace('/examining')
      //   return
      // }

      //这里预留的是初次进入页面时候调用的匹配api产品接口
      try {
        const { data } = await get_api_product()
        if (data.state === 0) {
          // api产品存入本地
          this.product = data.productDto

          // h5产品直接跳转链接，本地产品去中间页
          if (this.product.localState === 0) {
            try {
              // api h5产品进件
              const { data } = await apply_big_loan_product({
                productId: this.product.id,
                instApiState: this.product.instApiState,
                bigLoanType: this.product.isKlhdProduct
              })

              // 特殊渠道api的h5也直接跳下载
              if (process.env.VUE_APP_H5_API_PRODUCT_TO_DOWNLOAD) {
                this.$toDownload(0)
                return
              }

              if (!data.result) {
                this.$toast.fail('该产品已下架')

                // 进件失败跳贷超
                await this.$router.replace('/superLoan')
                return
              }

              // 跳转链接
              location.replace(data.productDto.h5Url)
            } catch (e) {}
          } else {
            // 该渠道匹配到本地api直接跳下载
            if (process.env.VUE_APP_SUPER_LOAN_ONLY_LOCAL_STATE) {
              this.$toDownload(0)
              return
            }

            // 需要先获取协议
            await this.getProtocolContentHtml()

            // api产品存入本地，在后续中间页需要使用
            localStorage.setItem('apiProduct', JSON.stringify(this.product))

            // 对弹出框的协议内容进行处理并弹出
            this.replaceProtocolContent()
          }
          return
        } else {
          if(data.state === 1) {
            await this.$router.replace('/superLoan')
            return
          }
          // 其他情况跳转至贷超前的中间页
          await this.$router.replace('/special')
        }
      } catch (e) {}

      // 没有匹配到产品的情况下跳下载
      if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
        this.$toDownload(0)
        return
      }

      // 其他情况跳转至贷超前的中间页
      await this.$router.replace('/special')
    },
    //获取个人信息授权书协议
    async getProtocolContentHtml() {
      this.bigAgreementName = ['个人信息授权书']
      this.bigAgreementFile = []
      try {
        const { data } = await get_agreement_list()
        data.forEach((protocol) => {
          if (protocol.name === "个人信息授权书") {
            this.protocolContentHtml = protocol.content

            if (process.env.VUE_APP_IS_SPECIAL_PERSONAL) {
              this.protocolContentHtml = personalContent
            }
            if (process.env.VUE_APP_PROTOCOL_PLATFORM_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll('{productName}', process.env.VUE_APP_PROTOCOL_PLATFORM_NAME)
            }
            if (process.env.VUE_APP_PROTOCOL_COMPANY_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll('{productCompanyName}', process.env.VUE_APP_PROTOCOL_COMPANY_NAME)
            }
            localStorage.setItem('agreementId', protocol.id)
          }
        })
      } catch (e) {}
    },
    // 处理协议，替换协议内容，弹出协议并获得截图
    replaceProtocolContent() {
      let protocolList = []
      if (this.product.xdApiAgreementList && this.product.xdApiAgreementList.length !== 0) {
        protocolList = this.product.xdApiAgreementList
      }

      // 把protocolList循环分别赋值给bigAgreementNameList和bigAgreementFileList
      this.product.bigAgreementName = protocolList.map((protocol) => protocol.name)
      this.product.bigAgreementFile = protocolList.map((protocol) => protocol.url)

      // 处理协议弹框顶部的多个协议
      if (this.product.bigAgreementName) {
        // api协议在获取时已经成为数组，因此不用重复调用split方法，其他为本地配置的协议时字段为字符串，因此改为数组
        const bigAgreementNameList = this.product.bigAgreementName
        const bigAgreementFileList = this.product.bigAgreementFile
        if (bigAgreementNameList.length === 1) {
          this.bigAgreementName.push(...bigAgreementNameList)
          this.bigAgreementFile.push(...bigAgreementFileList)
        } else {
          bigAgreementNameList.splice(1, 0, '个人信息授权书')
          bigAgreementFileList.splice(1, 0, '')
          this.bigAgreementName = bigAgreementNameList
          this.bigAgreementFile = bigAgreementFileList
          this.protocolsTitleIndex = 1
          this.$nextTick(() => {
            this.protocolsTitleIndex = 1
          })
        }
      }

      // 获取用户信息填入协议
      const userInfo = this.$getUserInfo()
      const userName = userInfo.userName.split('').map((item, index) => {
        if (index === 0) {
          return item
        } else {
          return '*'
        }
      }).join('')

      // 身份证号判空
      userInfo.idCard = userInfo.idCard ? userInfo.idCard : ""

      // 协议内容填充
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{name}",
          userName
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{idCard}",
          userInfo.idCard.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1********\$2")
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{time}",
          getNowDate()
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{institutionName}",
          this.product.name || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{companyName}",
          this.product.companyName || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{address}",
          this.product.address || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{productName}",
          this.product.productName || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{productCompanyName}",
          this.product.productCompanyName || ""
      )

      // 弹出协议
      this.protocolPopupIsShow = true

      // 截图状态开始时不可以点击提交
      this.stopSubmit = true

      // 弹出协议之后进行截图，截图需要等弹出动画结束
      setTimeout(() => {
        this.html2canvas()
      }, 300)
    },
    // 通过点击协议弹窗的同意按钮申请api产品
    doSubmitApiProduct() {
      // 开启3s倒计时动画
      this.countDownCoverIsShow = true

      localStorage.setItem('submitSuccess', '1')

      // 需要一个延时器来确保动画的加载时长
      setTimeout(async () => {
        try {
          // 截图保存给后端
          const base64Img = localStorage.getItem("base64Img")
          const file = base64Img ? this.base64ToFile(base64Img, "image") : ""
          save_personal_protocol_image({
            data: file,
            ...this.product
          })

          // api产品进件
          const { data } = await apply_big_loan_product({
            productId: this.product.id,
            instApiState: this.product.instApiState,
            bigLoanType: this.product.isKlhdProduct
          })
          
          localStorage.removeItem('applySubmit')
          
          localStorage.removeItem('applySubmit')
          if (!data.result) {
            this.$toast.fail('该产品已下架')

            // 进件失败跳贷超
            await this.$router.replace('/superLoan')
            return
          }

          // 推送api产品
          if (this.product.localState === 1) {
            await apply_api_product(this.product.id)
          }

          // 该值代表着在api产品中间页不会再次匹配产品
          localStorage.setItem('apiProductMiddleNotMatchAgain', '1')

          // 申请后跳转中间页
          await this.$router.replace('/apiProductMiddlePage')
        } catch (e) {
          // 发生异常时关闭倒计时动画
          this.countDownCoverIsShow = false
        }
      }, 1500)
    },
    refuseApiProductProtocol() {
      // 首先关闭协议弹窗
      this.protocolPopupIsShow = false
      // 等关闭动画300ms结束，弹出再次询问的弹框
      setTimeout(() => {
        this.refuseProtocolPopupIsShow = true
      }, 300)
    },
    // 用户取消申请api产品
    apiProductApplyCancel() {
      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
        this.$router.replace('/fail')
        return
      }

      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
        this.$router.replace('/fail')
        return
      }

      // 该渠道没有匹配到产品就直接跳下载页面
      if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
        this.$toDownload(0)
        return
      }

      localStorage.setItem('submitSuccess', '1')
      localStorage.setItem("applySubmit", "1");
      this.$router.replace('/superLoan')
    },
    // 截图
    getShareImgBase64() {
      return new Promise((resolve) => {
        setTimeout(() => {
          html2canvas(document.querySelector('.protocol-popup-container'), {
            // useCORS: true, // 【重要】开启跨域配置
            scale: 1
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0)
            resolve(imgData)
          })
        }, 800)
      })
    },
    html2canvas() {
      this.$nextTick(() => {
        this.getShareImgBase64().then((res) => {
          localStorage.setItem('base64Img', res)
          // 截图完成可以提交资料
          this.stopSubmit = false
        })
      })
    },
    // 转换截图为file
    base64ToFile(data, fileName) {
      const dataArray = data.split(',')
      const byteString = atob(dataArray[1])
      const options = {
        type: 'image/jpeg',
        endings: 'native'
      }
      const utf8Array = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        utf8Array[i] = byteString.charCodeAt(i)
      }
      const formData = new FormData()
      let fileOfBlob = new File([utf8Array], fileName +'.jpg', options)//返回文件流
      formData.append('image', fileOfBlob)
      return formData
    }
  },
  mounted() {
    const time = this.gifIsShow ? 2000 : 0
    setTimeout(() => {
      this.pageRedirect()
    }, time)
  }
}
</script>

<style lang="less" scoped>
#register-loading {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  padding-top: 3.307rem;
  color: #000000;

  .register-loading-icon-container {
    overflow: hidden;
    width: 6.213rem;
    height: 7.627rem;
    margin: 0 auto;
    background: url("https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/loading-background-image.png") no-repeat;
    background-size: 100%;
    text-align: center;
    font-size: 0.427rem;

    img {
      width: 3.6rem;
      margin: 1.28rem auto 1.947rem;
    }
  }

  .protocol-popup-container {
    overflow: hidden;
    width: 100vw;
    background: #ffffff;

    .protocol-content-container {
      width: 100%;
      height: 322px;
      padding: 0 29px;
      margin-bottom: 20px;
      font-size: 14px;

      ::v-deep .van-tab--active .van-tab__text {
        color: #000;
        font-size: 16px;
        font-weight: 600;
      }

      ::v-deep .van-tab {
        padding: 0 10px;
      }

      .protocol-content {
        overflow-y: auto;
        padding-top: 10px;
      }

      .protocol-content-no-scroll {
        overflow: hidden;
      }

      .protocol-content,
      iframe {
        width: 100%;
        height: 278px;
      }
    }

    .protocol-do-agree-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.733rem;
      height: 1.333rem;
      margin: 0 auto 0.4rem;
      background: #325EF3;
      border-radius: 0.667rem;
      font-size: 0.427rem;
      font-weight: 500;
      color: #FFFFFF;
    }

    .protocol-refuse-button {
      padding-bottom: 0.4rem;
      font-size: 0.373rem;
      text-align: center;
      color: #999999;
    }
  }

  .refuse-protocol-popup-container {
    width: 9.467rem;
    padding: 0.533rem 0.747rem;
    background: #FFFFFF;
    text-align: center;

    h3 {
      margin-bottom: 0.373rem;
      font-weight: 600;
      font-size: 0.533rem;
      color: #333333;
    }

    p {
      font-size: 0.48rem;
      color: #333333;
      line-height: 0.667rem;
    }

    img:first-of-type {
      margin: 0.667rem 0 0.293rem;
    }
  }

  .count-down-cover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    background: #FFF;

    img {
      width: 4.267rem;
      margin: 3.44rem auto 0;
    }
  }
}

.dark-mode .register-loading-icon-container {
    overflow: hidden !important;
    width: 6.213rem !important;
    height: 7.627rem !important;
    margin: 0 auto !important;
    background: url("https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/loading-background-image-dark.png") no-repeat !important;
    background-size: 100% !important;
    text-align: center !important;
    font-size: 0.427rem !important;
    color: #f5f5f5 !important;
}

.dark-style {
  border-radius: 50%;
}


.dark-mode {

  .refuse-protocol-popup-container {
    background: #1c1d1f !important;
    color: #f5f5f5 !important;

    h3 {
      color: #f5f5f5 !important;
    }

    p {
      color: #f5f5f5 !important;
    }
  }

  .count-down-cover {
    background: #1c1d1f !important;
  }
}
</style>